@import url("https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

@font-face {
  font-family: "OpenSansBold";
  font-style: normal;
  font-weight: normal;
  src: local("OpenSansBold"), url(./fonts/OpenSans-Bold.ttf) format("truetype"),
    url(./fonts/OpenSans-Bold.woff) format("woff");
}

@font-face {
  font-family: "OpenSansSemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("OpenSansSemiBold"),
    url(./fonts/OpenSans-SemiBold.ttf) format("truetype"),
    url(./fonts/OpenSans-SemiBold.woff) format("woff");
}

@font-face {
  font-family: "OpenSansLight";
  font-style: normal;
  font-weight: normal;
  src: local("OpenSansLight"),
    url(./fonts/OpenSans-Light.ttf) format("truetype"),
    url(./fonts/OpenSans-Light.woff) format("woff");
}

@font-face {
  font-family: "OpenSansReg";
  font-style: normal;
  font-weight: normal;
  src: local("OpenSansReg"),
    url(./fonts/OpenSans-Regular.ttf) format("truetype"),
    url(./fonts/OpenSans-Regular.woff) format("woff");
}

@font-face {
  font-family: "Galvji";
  font-style: normal;
  font-weight: normal;
  src: local("Galvji"), url(./fonts/Galvji-01.ttf) format("truetype"),
    url(./fonts/Galvji-01.woff) format("woff");
}

:root {
  /* Set neon color */
  --normalPadding: 2%;
  --mainPadding: 150px;
  --mainPaddingMobile: 27px;
  --normalColor: white;
  --menuGrey: #f3f3f3;
}

* {
  box-sizing: border-box;
  font-family: "OpenSansBold";
}

*:focus {
  outline: none;
  box-shadow: none;
}

*,
html,
body,
#root {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background: var(--normalColor);
  min-height: 100vh;
  z-index: -2;
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  display: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  transform: translateZ(0);
  object-fit: contain;
}

a {
  text-decoration: none;
  color: black;
}
a:visited {
  color: black;
}
a:focus {
  color: black;
  outline: none;
}
p {
  font-size: 20px;
  text-align: left;
  min-height: 15px;
}
.footer_menu_link,
.menu_link {
  padding: 8px 20px 8px 20px;
}
.footer_menu_link {
  margin-right: 25px;
}
.menu_link:hover {
  border-radius: 25px;
  background-color: var(--menuGrey);
  font-family: OpenSansBold, bold;
  white-space: nowrap;
  border: 0 solid rgba(0, 0, 0, 0);
}
.menu_link.active {
  border-radius: 25px;
  background-color: var(--menuGrey);
  font-family: OpenSansBold, bold;
  white-space: nowrap;
  border: 0 solid rgba(0, 0, 0, 0);
}
.footer_menu_link.active,
.footer_menu_link:hover {
  border-radius: 25px;
  background-color: white;
  font-family: OpenSansBold, bold;
  white-space: nowrap;
  border: 0 solid rgba(0, 0, 0, 0);
}

.blockContent p {
  text-align: left;
  font: normal normal normal 19px/40px Open Sans;
  letter-spacing: 0px;
  color: #030000;
}
.blockContent p strong {
  text-align: left;
  font: normal normal bold 19px/40px Open Sans;
  letter-spacing: 0px;
  color: #030000;
}
.blockContent p a {
  text-align: left;
  font: normal normal normal 19px/40px Open Sans;
  letter-spacing: 0px;
  color: #030000;
  text-decoration: underline;
}

h3 {
  text-align: left;
  font: normal normal bold 41px/56px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

h2 {
  font-family: OpenSansBold, bold;
  font-size: 19px;
}
h1 {
  font-family: OpenSansBold, bold;
  text-align: left;
  font: normal normal bold 27px Open Sans;
  letter-spacing: 0px;
  color: #030000;
  opacity: 1;
  margin-bottom: 20px;
}
.headline {
  padding-top: 10px;
}
.subheadline p,
.subheadline {
  text-align: left;
  font: normal normal normal 18px Open Sans;
  letter-spacing: 0px;
}

.fullwidth {
  width: 100%;
}

.basket {
  position: absolute;
  right: 0px;
  top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  max-height: 64px;
  min-width: 18px;
}
.basket img {
  width: 29px;
  height: 25px;
  max-width: none;
}
.basketCounter {
  position: absolute;
  top: -13px;
  right: -13px;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.basketCounter p {
  font-size: 15px;
}

.tag_grid {
  margin-top: 30px;
  margin-bottom: 20px;
}

.noMargin {
  margin: 0;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}
.invisible {
  visibility: hidden;
}

.flex-column {
  display: flex;
  flex-direction: column;
  position: relative;
}
.flex-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  align-items: center;
}
.reverse-direction {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.no-wrap {
  flex-wrap: nowrap;
}
.align-top {
  align-items: flex-start;
}
.align-bottom {
  align-items: flex-end;
  justify-content: flex-end;
}
/* .align-bottom div:first-of-type {
  width: 100%;
} */
.align-left {
  justify-content: flex-start;
}
.align-right {
  align-items: flex-end;
  justify-content: flex-end;
}
.align-right p {
  text-align: right;
}
.justifyBetween {
  justify-content: space-between;
}

.horizontalScroll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.horizontalScroll2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.horizontalScroll::-webkit-scrollbar {
  display: inline-block !important;
}

.standard-button {
  border-radius: 25px;
  padding: 6px 18px;
  background-color: var(--menuGrey);
  font-family: OpenSansBold, bold;
  white-space: nowrap;
  border: 0 solid rgba(0, 0, 0, 0);
  font-size: 15px;
  margin-right: 15px;
  margin-top: 10px;
  border: 3px solid inherit;
}

.standardTransparent-button {
  padding: 6px 18px;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  text-align: left;
  font: normal normal bold 15px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 10px;
  margin-right: 13px;
  border: 3px solid #090206;
  border-radius: 70px;
  opacity: 1;
}

.extraBorder {
  border: 3px solid #090206;
}
.extraBordergrey {
  border: 3px solid var(--menuGrey);
}

.fullWidthPadded {
  padding-left: var(--mainPadding);
  padding-right: var(--mainPadding);
}
.fullWidthPaddedLeft {
  padding-left: var(--mainPadding);
  padding-right: 50px;
}
.overscrollPadded {
  padding-left: var(--mainPadding);
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.tag,
.tag_button {
  margin-right: 15px;
}
.tag {
  margin-bottom: 0 !important;
}
.tag.active,
.tag_button.active {
  color: white;
  background-color: black;
  border: 3px solid black;
}

.mainImage {
  margin: 0;
  min-height: 580px;
  max-height: 850px;
  object-fit: cover;
}

.stars {
  font-size: 13px !important;
}
.productCard-stars {
  margin-top: -9px;
}
.singelPageStars {
  font-size: 20px !important;
}
.project_directory_line {
  padding-bottom: 7px;
  margin-left: var(--mainPadding);
  margin-right: var(--mainPadding);
  margin-bottom: 45px;
  border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
}

.project_directory_line > * {
  margin-right: 10px;
}

.topLineOnDesktop {
  border-top: 1px rgba(0, 0, 0, 0.15) solid;
}

/*HOME ABOUT SECTION*/

.authorImage {
  position: relative;
  height: 100%;
  /* min-height: 800px; */
  width: 40%;
  object-fit: contain;
  margin-top: 3%;
  margin-right: 3%;
  /* padding-right: 67px; */
}
.aboutSection {
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 800px; */
}
.aboutSection .contactDetails a,
.aboutSection .blockContent a,
.aboutSection .blockContent p,
.contactDetails p,
.contactDetails a {
  text-align: left;
  font: normal normal normal 18px Open Sans;
  letter-spacing: 0px;
  color: #030000;
}
.contactDetails .flex-column {
  flex-grow: 1;
}

.aboutContent {
  margin-bottom: 69px;
}

.contactDetails h2 {
  margin-bottom: 40px;
  text-align: left;
  font: normal normal bold 19px Open Sans;
  letter-spacing: 0px;
  color: #030000;
}
.segmentHeadline {
  margin-bottom: 20px;
}
.contactDetails .flex-column {
  margin-bottom: 71px;
}

.contactDetails {
  background-color: var(--menuGrey) !important;
  padding-top: 30px;
  margin-top: 150px;
  z-index: 9999999999999999999999;
}
.content-container {
  position: relative;
}

.mainContainer {
  position: relative;
  padding-top: 200px;
  min-height: 90vh;
}
.regContainer {
  padding-top: 100px;
  z-index: 0;
  position: relative;
  /* background-color: white; */
}
.no-top {
  padding-top: 0px !important;
}

/* .cover {
  box-shadow: 2px -50px 50px 34px #ffffff;
} */

.regContainer:last-of-type {
  padding-bottom: 30px;
}

.addToCartButton {
  background-color: #4bc35d;
  border-radius: 49px;
  opacity: 1;
  color: white;
  border: 0px #4bc35d solid;
  text-align: center;
  font: normal normal bold 18px/21px Galvji;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 15px 0px;
  width: auto;
  border-bottom: rgba(0, 0, 0, 0) !important;
  width: 100%;
  cursor: pointer;
}
.addToCartButton:last-of-type {
  margin-top: 11px;
  margin-bottom: 42px;
}
.addToCartButton:first-of-type {
  margin-top: 42px;
  /* margin-bottom: 42px; */
}

.addToCartButton.yellow {
  background-color: #ffa700;
}

.addToCartButton.grey {
  background-color: var(--menuGrey) !important;
}

/* search bar menu */

.rightMenu {
  white-space: nowrap;
  display: flex;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 99;

  pointer-events: none;
}
.popupcontainer {
  visibility: visible;

  pointer-events: all;
}
.popup {
  z-index: 99999999999;
  width: 80%;
  height: auto;
  opacity: 1;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup img {
  border-radius: 20px;
}
.popupheader {
  margin-top: -5px;
  height: 0px;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: white;
}
.popupheader img {
  height: 30px;
}
.popupheader button {
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0) solid 1px;
  margin-top: 10px;
  margin-left: 5px;
}

.megaOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #000000;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  visibility: hidden;
  pointer-events: none;
}
.megaOverlay.active {
  visibility: visible;
  pointer-events: all;
}
.overlay.active {
  visibility: visible;
  pointer-events: all;
}

.dropdown {
  position: absolute;
  z-index: 0;
  top: 8px;
  right: 46px;
  width: 100%;
  border-radius: 34px;
  transition: border-radius 2s, background-color 2s ease;
  background: var(--menuGrey);
}
.dropdown.active {
  border-radius: 20px;
  transition: border-radius 2s ease;
  background-color: white;
  z-index: 999;
}

.dropdown__text {
  color: white;
  padding: 12px 16px;
  cursor: pointer;
  height: auto;
}
.dropdown__text p {
  text-align: left;
  font: normal normal normal 15px Galvji;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.33;
  padding-left: 30px;
}

.dropdown__item a {
  padding-left: 30px;
}

.dropdown__items {
  position: relative;
  visibility: hidden;

  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s, opacity 0.5s, visibility 1s ease;
}
.dropdown .active {
  z-index: 999;
}
.dropdown.active .dropdown__items {
  visibility: visible;
  height: auto;
  max-height: 800px;
  pointer-events: all;
  opacity: 1;
  transition: max-height 0.7s, opacity 3s, visibility 4s ease;
}
.dropdown__item a,
.dropdown__item {
  cursor: pointer;
  pointer-events: none;
  padding: 5px 18px;
  transition: max-height 0.7s, opacity 3s, visibility 4s ease;
}
.dropdown__item:first-of-type {
  cursor: pointer;
  padding: 20px 18px 5px 18px;
  transition: max-height 0.7s, opacity 3s, visibility 4s ease;
}
.dropdown.active .dropdown__item a,
.dropdown.active .dropdown__item {
  pointer-events: all;
}

.dropdown__item:last-of-type {
  cursor: pointer;
  padding: 10px 18px 30px 18px;
  transition: max-height 0.7s, opacity 3s, visibility 4s ease;
}

.foldout {
  position: fixed;
  top: 0;
  right: -40%;
  height: 100%;
  width: 40%;
  background-color: white;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow-y: scroll;
  z-index: 100;
}
.foldout.active {
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.foldoutmenu {
  max-width: 100%;
  z-index: 9999999;
}
.foldoutDiv {
  width: 100%;
  padding: 30px 30px 30px 30px;
}

.foldoutHeader {
  background-color: #4bc35d;
}
.foldoutHeader .closeCrossBasket {
  float: right;
  width: 40px;
}

.foldoutHeader p,
.foldoutHeader h2 {
  white-space: pre-wrap;
  color: white;
  width: 90%;
}
.foldoutHeader h2 {
  margin-top: 70px;
  font-size: 27px;
}
.foldoutHeader p {
  text-align: left;
  font: normal normal normal 15px Open Sans;
  letter-spacing: 0px;
}
.foldoutHeader a {
  text-align: left;
  font: normal normal normal 15px Open Sans;
  letter-spacing: 0px;
  text-decoration: underline;
  color: white;
}

.basketCard {
  position: relative;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 31px;
  border-bottom: 1px solid var(--menuGrey);
}

.basketCard_image_link {
  width: 30%;
  /* padding-bottom: 50%; */
  position: relative;
  overflow: hidden;
  position: relative;
}
.basketCard_image_link .basketCard_image {
  width: 100%;
}
.basketCard_image_link .basketCard_image img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.basketCard .details {
  padding-top: 31px;
  width: 48%;
  padding-left: 15px;
}
.basketCard .details h2 {
  text-align: left;
  font: normal normal bold 19px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  white-space: pre-wrap;
}
.basketCard .details .tag {
  text-align: left;
  font: normal normal normal 15px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
/* frontpage grids */

/* tags */

.tag {
  margin-right: 5px;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  padding: var(--mainPadding);
  padding-top: 76px;
  padding-bottom: 0px;
}

.header-object {
  text-align: left;
  font: normal normal bold 19px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.menu a {
  margin-right: 10px;
}
.menu a:first-of-type {
  margin-right: 10px;
  margin-left: 15px;
}

.logo {
  max-height: 60px;
  min-width: 150px;
}

.header-flex-row {
  max-height: 60px;
}

nav .logo_container img {
  position: absolute;
  z-index: -1;
}
.logo_container p {
  white-space: nowrap;
}

/* posts */

.post_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.post_card {
  position: relative;
  height: auto;
  margin-bottom: 12px;
}
.post_card_image {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}

.post_card_overlay {
  position: absolute;
  z-index: 99;
  padding: 10%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  justify-items: space-between;
  margin-bottom: 5px;
}
.post_card_overlay .details {
  margin: 0;
}

.projectList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.projectList-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}

.projectList-item a,
.projectList-item p,
.projectList-item p u {
  font: normal normal normal 20px Open Sans;
  letter-spacing: 0px;
  color: #030000;
  opacity: 1;
}
.projectList-item:first-of-type {
  margin-bottom: 29px;
}

.projectList-item div {
  flex-grow: 1;
  text-align: left;
}

.align-with-projectlist-categories,
.projectList-item .categories {
  margin-right: 20%;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 230px;
  flex-grow: 2;
}
.projectList-item .categories a {
  width: auto;
  position: relative;
  margin-right: 3px;
  white-space: nowrap;
}

.thumbnail {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-15%, -50%);
  max-height: 80%;
  z-index: -99;
  max-width: 600px;
  opacity: 0.9;
}
.minThumbnail {
  max-height: 400px;
}

/* featured posts */
.carousel {
  overflow: visible !important;
}
.carousel .slide iframe {
  margin: 0 !important;
  width: 100% !important;
  min-width: 100%;
  overflow: visible !important;
}

.carousel .slide {
  display: flex;
  justify-content: center;
  overflow: visible !important;
}

@media (max-width: 768px) {
  .slider-wrapper.axis-horizontal > ul {
    top: 0;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    opacity: 1;
    z-index: auto;
  }
}

.dot {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  background-color: black !important;
  opacity: 0.07 !important;
  width: 6px !important;
  height: 6px !important;
}
.swiper {
  overflow-y: visible !important;
}
.dot.selected {
  opacity: 0.4 !important;
}

.whiteboxCarousel .carousel .control-dots {
  bottom: -40px !important;
}

/* .videoCover img {
  z-index: 9999999;
} */
.whiteboxCarousel .carousel .slider-wrapper .slide .videoCover img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
span:has(.whiteBoxImage) {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.whiteBoxImage {
  max-height: 100%;
}
.swiper,
.sliderWrapper {
  height: 100%;
}
.featuredCardArrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  height: 45px;
  width: 45px;
  background-color: rgba(0, 0, 0, 0) !important;
  border: rgba(0, 0, 0, 0) 0px solid !important;
}
.nextArrow {
  right: 0;
  transform: translate(50%, -50%);
}

span:has(.mainImage) {
  width: 100%;
}
.arrow {
  width: 40px;
}

.prevArrow {
  left: 0;
  transform: translate(-50%, -50%);
}

.closeCross {
  width: 60px !important;
  height: 60px !important;
  z-index: 999999;
}

.featured_post_container {
  min-height: 580px;
  max-height: 800px;
  display: inline-block;
}
.featured_post_card {
  min-height: 580px;
  max-height: 650px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
}
/* .featured_post_card a {
  height: 100%;
  min-height: 580px;
  max-height: 650px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
} */
.details {
  height: auto;
  width: 100%;
}

.featured_post_card_image {
  flex-grow: 2;
  max-width: 66%;
  height: 100%;
  min-height: 650px;
}
.featured_post_card_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 650px;
}
.featured_post_card_overlay {
  padding: 108px 46px 46px 46px;
  flex-grow: 1;
  width: 33%;
  height: 100%;
  min-height: 580px;
  height: 650px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.featuredCardTag {
  text-align: left;
  font: normal normal normal 25px/34px Open Sans;
  letter-spacing: 0px;
}
.post_category_list {
  flex-direction: row;
}
.post_category_list p {
  margin-right: 5px;
  margin-top: 5px;
}
.post_category_list:nth-child(0) {
  margin-top: 0;
}

/* product card */

.product_card {
  width: 100%;
  /* height: 611px; */
  max-width: 416px;
  /* min-height: 611px; */
  margin-bottom: 5px;
  margin-right: 5px;
}
.horizontal .product_card {
  width: 350px;
  /* height: 611px; */
  min-width: 350px;
  min-height: 550px;
  margin-right: 10px;
}

/* .product_card_in_grid {
  max-width: 100% !important;
  min-width: 100% !important;
} */
.product_card:last-of-type {
  margin-right: var(--mainPadding);
}

.product_card_image {
  width: 100%;
}
.product_card_image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.product_card h1 {
  text-align: left;
  font: normal normal bold 19px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0;
  margin-top: 29px;
}

.product_card p {
  text-align: left;
  font: normal normal normal 13px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.year_price p {
  margin-top: 5px;

  text-align: left;
  font: normal normal bold 35px Open Sans !important;
  letter-spacing: 0px;
  color: #030000;
}
.year_price p:last-of-type {
  margin-top: 0px;
  text-decoration: none;
  font: normal normal bold 50px Open Sans !important;
  letter-spacing: 0px;
  color: #030000;
}
/* SINGLE POST */

.contentColumn {
  width: 60%;
  /* flex: 1; */
  /* margin-right: 65px; */
}
.detailColumn {
  /* flex-grow: 1; */
  width: 40%;
  padding-right: var(--mainPadding);
}

.detailColumnUnfixed {
  width: 40%;
  padding-right: var(--mainPadding);
}

.squareImage {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  position: relative;
}
.squareImage iframe,
.squareImage img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recap {
  margin-top: 50px;
  margin-bottom: 40px;
}
.recap p {
  text-align: left;
  font: normal normal 300 25px Open Sans !important;
  letter-spacing: 0px;
  color: #030000;
  opacity: 1;
}

/* .content {
  border-top: 1px var(--menuGrey) solid;
} */
.content p {
  text-align: left;
  font: normal normal normal 18px Open Sans;
  letter-spacing: 0px;
  color: #030000;
  opacity: 1;
}

.content .header {
  height: 136px;
  margin-bottom: 10px;
}
.content .header img {
  width: 29px;
  height: 30px;
}

.projectTitle {
  text-align: left;
  font: normal normal bold 35px/47px Open Sans;
  letter-spacing: -0.53px;
  color: #000000;
  opacity: 1;
}

.project_year_price p:last-of-type {
  text-align: left;
  font: normal normal bold 35px Open Sans !important;
  letter-spacing: -0.53px;
  color: #000000;
  opacity: 1;
}

.project_year_price p {
  font: normal normal bold 15px Open Sans !important;
}
.project_tags {
  margin-top: 23px;
}
.postCardTag {
  font: normal normal normal 18px Open Sans;
}
.project_directory_line p,
.project_directory_line a,
.project_tag p,
.project_tag u,
.project_tag {
  text-align: left;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.project_details {
  border-top: 1px rgba(0, 0, 0, 0.15) solid;
}
.project_details .content {
  border-top: 1px rgba(0, 0, 0, 0.15) solid;
}
.project_details h3 {
  text-align: left;
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #030000;
  margin-top: 25px;
}

/* masonry */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  position: relative;
  margin-right: 15px;
  background-clip: padding-box;
}

.my-masonry-grid_column:last-of-type {
  margin-right: 0px;
}
.singleProjectMasonry:last-of-type {
  margin-right: 15px;
}

/* Style your items */
.my-masonry-grid_column div,
.my-masonry-grid_column a > div {
  margin-bottom: 15px;
}

/* footer */

footer {
  min-height: 217px;
  background-color: var(--menuGrey);
  display: flex;
  flex-direction: column;
}
.footerLogo {
  margin-right: 83px;
  min-height: 115px;
  max-height: 115px;
  z-index: 99999999 !important;
  min-width: 30px;
}

.footer-object {
  margin-right: 36px;
}

.footer_social_media_icon {
  width: 30px;
  align-self: center;
  object-fit: contain;
  margin-left: 36px;
}
#footer_social_media_icon0 {
  margin-left: 0px;
}

.footer_top {
  margin-bottom: 35px;
  padding-left: var(--mainPadding);
  padding-right: var(--mainPadding);
}

.footer_bottom {
  padding-left: var(--mainPadding);
  padding-right: var(--mainPadding);
}

.footer_bottom .blockContent strong,
.footer_bottom .blockContent a,
.footer_bottom .blockContent p {
  font-size: 14px;
}

.scrolltoTop {
  margin: 36px;
  position: relative;
  width: 55px;
  height: 55px;

  right: 0;
}

/* email */
.contact-form textarea,
.contact-form input {
  border-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid black;
  background-color: white;
}
.contact-form input:focus {
  outline: none;
}

.emailForm-h2 {
  padding-top: 30px;
}
input {
  border-bottom: 2px var(--menuGrey) solid !important;
  padding: 5px;
  font-size: 16px;
  text-align: left;
  font: normal normal normal 16px Open Sans !important;
  letter-spacing: 0px;
  color: #000000;
}
input::placeholder {
  text-align: left;
  font: normal normal normal 16px Open Sans !important;
  letter-spacing: 0px;
  color: black;
  opacity: 0.6;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
    text-align: left;
    font: normal normal normal 16px Open Sans !important;
    letter-spacing: 0px;
    color: #000000;
  }
}

/* loading-animation */

.cell {
  display: inline-block;
  padding-top: 10%;
  width: 100%;
  text-align: center;
}
.circle {
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: red;
}

.gelatine {
  animation: gelatine 10s infinite;
}

.whitebox .carousel-root,
.whitebox .carousel,
.whitebox .slider-wrapper,
.whitebox .slider {
  max-height: 100% !important;
  height: 100% !important;
}
.whiteboxCarousel img {
  height: 100%;
  object-fit: contain;
}
.slide span {
  max-height: 100%;
}
.freebieSign h2 {
  color: black;
  padding: 5px;
  font: normal normal bold 16px Open Sans;
  font-style: italic;
  text-align: center;
  white-space: nowrap;
}
.freebieSign {
  position: absolute;
  left: 40%;
  background-color: #f8ff52;
  box-shadow: 3px 3px 0px 0px #ff0000;
  padding: 4px;
  margin-left: 10px;
  margin-top: -10px;
}

.buttonOnWrapList {
  margin-top: 20px;
}

.backArrow {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
  padding: 30px 27px 30px 25px;
  z-index: 9;
  mix-blend-mode: difference;
  opacity: 0.4;
  display: none;
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(-1, 1);
  }
  100% {
    transform: scale(-1, -1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1300px) {
  /* .singleProjectMasonry {
    padding-right: 7.5px;
  } */
  .singleProjectMasonry:last-of-type {
    padding-right: 0;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .detailColumnfixed {
    position: fixed;
    right: 0;
    top: 61px;
    width: 40%;
    padding-right: var(--mainPadding);
  }
}
@media only screen and (max-width: 1310px) {
  footer {
    min-height: 225px;
    /* margin-top: 93px; */
    background-color: var(--menuGrey);
    display: flex;
    flex-direction: column;
  }
  .footerLogo {
    margin: 0;
    align-self: center;
    min-height: 94px !important;
  }
  .footer-object {
    margin-right: 36px;
  }

  .footer_social_media_icon {
    width: 40px;
    align-self: center;
    object-fit: contain;
    margin-left: 36px;
  }

  .footer_top {
    margin-bottom: 35px;
    padding-left: var(--normalPaddingMobile);
    padding-right: var(--normalPaddingMobile);
    background-color: black;
  }

  .footer_bottom {
    padding-left: var(--mobilePadding);
    padding-right: var(--mobilePadding);
  }
  .footer_bottom .flex-row {
    padding-left: 36px;
    padding-right: 36px;
  }
  .justifyBetween.footer_top {
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  h3 {
    text-align: left;
    font: normal normal bold 31px Open Sans;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
  }
  .featured_post_card_overlay {
    padding: 108px 26px 26px 26px;
    min-width: 20px;
    min-height: 0;
  }

  .featured_post_card_image {
    flex-grow: 2;
    max-width: 50%;
    height: 100%;
    object-fit: cover;
  }
  .featured_post_card_image img {
    min-height: 0px;
  }

  .contentColumn {
    width: 100%;
    margin-right: 0px;
  }
  .detailColumn {
    margin-top: 30px;
    max-width: 100%;
    width: 100%;
  }
  .project_details {
    border-top: 0px var(--menuGrey) solid;
  }

  .normPaddingMobile {
    padding-left: var(--mainPadding) !important;
    padding-right: var(--mainPadding) !important;
  }
}

@media only screen and (max-width: 1050px) {
  .backArrow {
    display: block;
  }

  .foldoutHeader h2 {
    margin-top: 40px;
    font-size: 19px;
  }

  .foldout {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 100%;
    background-color: white;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    overflow-y: scroll;
    z-index: 100;
  }
  .foldout.active {
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  .mainImage {
    min-height: 502px;
  }
  .authorImage {
    width: 100%;
    height: 100%;
    padding-right: 0px;
    object-fit: cover;
    margin: 0;
  }
  .reverse-direction {
    flex-direction: column;
    /* justify-content: space-between; */
  }
  .logo_container,
  .logo_container p {
    width: 100%;
    text-align: center;
  }

  .logo {
    max-height: 50px;
    min-width: 0px;
  }

  .mainContainer {
    padding-top: 190px;
    min-height: 90vh;
  }

  .fullWidthPadded {
    padding-left: var(--mainPaddingMobile);
    padding-right: var(--mainPaddingMobile);
  }
  .project_directory_line {
    margin-left: var(--mainPaddingMobile);
    margin-right: var(--mainPaddingMobile);
    margin-bottom: 45px;
  }
  .directoryTag {
    font-size: 9px !important;
  }
  .fullWidthPaddedLeft {
    padding-left: var(--mainPaddingMobile);
    padding-right: 10px;
  }

  .overscrollPadded {
    padding-left: var(--mainPaddingMobile);
  }

  nav {
    padding: var(--mainPaddingMobile);
    padding-top: 40px;
    padding-bottom: 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    z-index: 9999999999;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .carousel {
    width: 100%;
  }
  .details header {
    margin-top: 25px;
  }

  .featured_post_card {
    height: 502px;
    min-width: 320px !important;
    max-width: 320px !important;
    flex-grow: none;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 19px;
    margin-bottom: 19px;
  }

  .featured_post_card_image {
    min-height: 50%;
    min-width: 320px !important;
    object-fit: cover;
  }
  .featured_post_card_overlay {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 29px 26px 26px 26px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
  }

  .featuredCardTag {
    text-align: left;
    font: normal normal normal 10px/14px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .featured_post_card_overlay .details h3 {
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .standardTransparent-button {
    border: 2px solid #090104;
    border-radius: 70px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .standard-button {
    text-align: left;
    font: normal normal bold 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-right: 15px;
  }

  .subheadline {
    margin-bottom: 0px;
  }

  .tag_grid {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .product_card {
    max-width: 173px;
    width: 100%;
    height: auto;
    min-width: 173px;
    min-height: 263px;
  }
  .horizontal .product_card {
    max-width: 173px;
    width: 100%;
    height: auto;
    min-width: 173px;
    min-height: 263px;
  }

  .minHeightAdjustment {
    min-height: 36px;
    align-content: flex-start;
  }

  .griddedMasonry .my-masonry-grid_column .product_card {
    max-width: 100% !important;
    border-bottom: 2px var(--menuGrey) solid;
  }
  .griddedMasonry .my-masonry-grid_column {
    margin: 0;
    border-top: 2px var(--menuGrey) solid;
  }
  .griddedMasonry .my-masonry-grid_column .product_card .details {
    padding: 10px;
  }
  .griddedMasonry .my-masonry-grid_column:first-of-type {
    border-right: 1px var(--menuGrey) solid;
  }
  .griddedMasonry .my-masonry-grid_column:last-of-type {
    border-left: 1px var(--menuGrey) solid;
    /* border-right: 0px var(--menuGrey) solid; */
  }

  .griddedMasonry .my-masonry-grid_column:only-of-type {
    border-left: 0px var(--menuGrey) solid;
    border-right: 2px var(--menuGrey) solid;
  }

  .product_card_in_grid {
    min-width: 0;
    max-width: 100%;
  }
  .product_card {
    margin-bottom: 0 !important;
  }
  .product_card:last-of-type {
    margin-right: var(--mainPaddingMobile);
  }

  .product_card_image {
    width: 100%;
    /* height: 173px; */
  }

  .product_card h1 {
    text-align: left;
    font: normal normal bold 15px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 14.6px;
  }

  .product_card p {
    text-align: left;
    font: normal normal normal 13px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 5px;
  }

  .year_price p {
    margin-top: 5px;
    text-align: left;
    font: normal normal bold 19px Open Sans !important;
    letter-spacing: 0px;
    color: #030000;
  }
  .year_price p:last-of-type {
    margin-top: 2px;
    text-decoration: none;
    font: normal normal bold 27px Open Sans !important;
    letter-spacing: 0px;
    color: #030000;
  }

  .subheadline p,
  .subheadline {
    text-align: left;
    font: normal normal normal 15px Open Sans;
    letter-spacing: 0px;
  }

  .aboutSection {
    max-width: 100%;
  }
  .aboutSection .contactDetails a,
  .aboutSection .blockContent a,
  .aboutSection .blockContent p,
  .contactDetails p,
  .contactDetails a {
    text-align: left;
    font: normal normal normal 13px Open Sans;
    letter-spacing: 0px;
    color: #030000;
  }

  .aboutContent {
    /* margin-bottom: 69px; */
    margin-top: 39px;
  }

  .aboutContent p {
    text-align: left;
    /* font: normal normal normal 21px Open Sans; */
    letter-spacing: 0px;
    color: #030000;
    opacity: 1;
  }

  .contactDetails h2 {
    margin-bottom: 40px;
    text-align: left;
    font: normal normal bold 19px Open Sans;
    letter-spacing: 0px;
    color: #030000;
  }
  .contactDetails .flex-column {
    margin-bottom: 71px;
  }

  .contactDetails {
    background-color: var(--menuGrey) !important;
    /* padding: 30px 27px 30px 32px; */
    flex-direction: column;
    z-index: 9999999999999999;
  }

  .projectList-item a,
  .projectList-item p,
  .projectList-item p u {
    text-align: left;
    font: normal normal normal 13px/20px Open Sans;
    letter-spacing: 0px;
    color: #030000;
  }

  .postCardTag {
    font: normal normal normal 15px Open Sans;
  }

  .projectList-item .categories {
    margin-right: 10%;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 30%;
    flex-grow: 1;
  }
  .projectList-item h2 {
    text-align: left;
    font: normal normal bold 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .project_directory_line {
    padding-bottom: 7px;
    margin-bottom: 0px;
    border-bottom: 1px rgba(0, 0, 0, 0.3) solid;
    display: none;
  }
  .project_directory_line + div {
    margin-top: 5px;
  }

  .my-masonry-grid_column {
    padding-right: 0px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column:last-of-type {
    padding-right: 0px;
  }

  /* Style your items */
  .my-masonry-grid_column a > div {
    margin-bottom: 3px;
  }
  .my-masonry-grid_column {
    margin-right: 6px;
  }
  .my-masonry-grid_column div,
  .my-masonry-grid_column a > div {
    margin-bottom: 6px;
  }

  .dropdown {
    position: relative;
    right: 0;
    width: 100%;
    margin-top: 20px;
    border-radius: 34px;
    transition: border-radius 2s ease;
    background: var(--menuGrey);
  }
  .normPaddingMobile {
    padding-left: var(--mainPaddingMobile) !important;
    padding-right: var(--mainPaddingMobile) !important;
  }

  .basket {
    right: 15px;
  }

  .basketCard .details {
    padding-top: 31px;
    width: 70%;
    padding-left: 15px;
  }
  .basketCard .details h2 {
    text-align: left;
    font: normal normal bold 17px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    white-space: pre-wrap;
  }
  .basketCard .details .tag {
    text-align: left;
    font: normal normal normal 13px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .addToCartButton:first-of-type {
    margin-top: 30px;
  }
  .addToCartButton:last-of-type {
    margin-bottom: 22px;
  }

  .scrolltoTop {
    margin: 2px 36px 36px 36px;
    position: relative;
    width: 40px;
    height: 40px;
    right: 0;
  }

  .horizontalScroll .tag_button {
    margin-bottom: 30px;
  }

  .horizontalScroll .tag_button {
    margin-bottom: 19px;
  }

  .fcButton {
    padding: 5px 10px 5px 10px;
  }
  .singelPageStars {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 570px) {
  nav {
    padding-top: 35px;
  }

  .mainContainer {
    padding-top: 140px;
    min-height: 90vh;
  }
  .categoryTitle {
    margin-top: 13px;
  }

  .regContainer {
    padding-top: 50px;
  }

  .categoryGrid {
    padding: 0;
  }
  .product_card {
    padding: 12px;
  }

  .header-object {
    text-align: left;
    font: normal normal bold 12px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .freebieSign h2 {
    color: black;
    padding: 5px;
    font: normal normal bold 12px Open Sans;
    font-style: italic;
    text-align: center;
    white-space: nowrap;
  }
  .freebieSign {
    position: absolute;
    left: 40%;
    background-color: #f8ff52;
    box-shadow: 3px 3px 0px 0px #ff0000;
    padding: 4px;
    margin-left: 10px;
    margin-top: 0px;
  }

  .subheadline {
    margin-bottom: 0px;
    text-align: left;
    font: normal normal normal 15px/20px Open Sans;
    letter-spacing: 0px;
    color: #030000;
  }

  .to-column {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .logo {
    max-height: 35px;
    min-width: 80px;
  }

  h1 {
    text-align: left;
    font: normal normal bold 19px/26px Open Sans;
    letter-spacing: 0px;
    color: #030000;
    opacity: 1;
    margin-bottom: 11px;
  }

  .menu {
    flex-wrap: nowrap;
    width: 85%;
    /* background-color: red; */
    justify-items: space-between;
    justify-content: space-between;
  }
  .menu a {
    margin-left: 14px;
    margin-right: 0;
  }
  .menu a:first-of-type {
    margin-right: 0px;
  }
  .menu a:last-of-type {
    margin-left: 0px;
  }
  .logo {
    margin: 0 !important;
  }

  .social_media_icon {
    width: 18px;
    height: 16px;
  }

  .social_media_icon {
    width: 18px;
    height: 16px !important;
  }

  .arrow {
    width: 34px;
  }

  /* SINGLE POST */

  .detailColumn {
    margin-top: 0px;
    max-width: 100%;
  }
  .recap {
    margin-top: 100px;
    margin-bottom: 25px;
  }

  .recap p {
    text-align: left;
    font: normal normal 300 20px Open Sans !important;
    letter-spacing: 0px;
    color: #030000;
    opacity: 1;
  }

  .content {
    border-top: 0px var(--menuGrey) solid;
    margin-bottom: 30px;
  }
  .content p {
    text-align: left;
    font: normal normal normal 15px/20px Open Sans;
    letter-spacing: 0px;
    color: #030000;
    opacity: 1;
  }

  .content .header {
    height: 59px;
    margin-bottom: 30px;
  }

  .content .header h2,
  .projectTitle {
    text-align: left;
    font: normal normal bold 20px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    max-width: 80%;
  }
  .content .header img {
    width: 17px;
    height: 18px;
  }

  .project_year_price p:last-of-type {
    text-align: left;
    font: normal normal bold 15px Open Sans !important;
    letter-spacing: -0.53px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0;
  }
  .project_year_price {
    position: absolute;
    top: 0;
    right: 0;
  }

  .project_year_price p {
    margin: 0;
    font: normal normal bold 10px Open Sans !important;
  }
  .project_tags {
    margin-top: 0px;
  }
  .project_tags .tag {
    margin-right: 3px;
  }
  .tag p,
  .project_tag u,
  .project_tag,
  .project_directory_line p,
  .project_tag div p,
  .project_directory_line a {
    text-align: left;
    font: normal normal normal 13px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .project_details h3 {
    text-align: left;
    font: normal normal bold 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #030000;
  }

  .basket {
    position: absolute;
    right: 4px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    max-height: 64px;
    min-width: 18px;
  }
  .basket img {
    width: 18px;
    height: 16px;
    max-width: none;
  }

  .basketCounter {
    position: absolute;
    top: -8px;
    right: -7px;
    background-color: red;
    color: white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .basketCounter p {
    font-size: 10px;
  }

  .dropdown.active {
    border-radius: 20px;
    transition: border-radius 2s ease;
  }
  .dropdown svg,
  .dropdown img {
    max-width: 13px;
  }
  .dropdown {
    margin-top: 10px;
  }

  .dropdown__text {
    color: white;
    padding: 10px 16px;
    cursor: pointer;
    height: auto;
  }
  .dropdown__text p {
    text-align: left;
    font: normal normal normal 14px/17px Galvji;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.33;
    padding-left: 10px;
  }

  .dropdown__item a {
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
  }

  .dropdown__items {
    position: relative;
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
    transition: max-height 0.6s, opacity 0.5s, visibility 1s ease;
  }

  .dropdown.active .dropdown__items {
    visibility: visible;
    height: auto;
    max-height: 800px;
    opacity: 1;
    transition: max-height 0.7s, opacity 3s, visibility 4s ease;
  }

  .dropdown__item {
    cursor: pointer;
    padding: 5px 18px;
    transition: max-height 0.7s, opacity 3s, visibility 4s ease;
  }

  .carousel {
    margin-bottom: 20px !important;
  }
  .control-dots {
    position: absolute;
    top: 99%;
  }

  .contactDetails {
    margin-top: 80px;
  }

  .projectList-item div {
    flex-grow: 0;
  }

  .projectList-item:first-of-type {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 390px) {
  .dropdown__text p {
    text-align: left;
    font: normal normal normal 12px Galvji;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.33;
    padding-left: 10px;
  }

  .footer_social_media_icon {
    width: 25px;
    align-self: center;
    object-fit: contain;
    margin-left: 36px;
  }

  .product_card {
    padding: 12px;
    min-width: 130px;
  }
  .product_card h1 {
    font-size: 11px;
    /* min-height: 24px; */
  }
  .product_card .tag {
    font-size: 11px;
    /* min-height: 24px; */
  }
  .minHeightAdjustment {
    min-height: 20px;
    align-content: flex-start;
  }

  .year_price p {
    font: normal normal bold 14px Open Sans !important;
  }
  .year_price p:last-of-type {
    font: normal normal bold 22px Open Sans !important;
  }
  .stars {
    font-size: 12px;
  }

  .freebieSign h2 {
    color: black;
    padding: 2px;
    font: normal normal bold 10px Open Sans;
    font-style: italic;
    text-align: center;
    white-space: nowrap;
  }
  .freebieSign {
    position: absolute;
    left: 50%;
    background-color: #f8ff52;
    box-shadow: 3px 3px 0px 0px #ff0000;
    padding: 2px;
    margin-top: 0px;
  }
  .footerLogo {
    /* margin-right: 83px; */
    min-height: 30px;
    max-height: 90px;
    z-index: 99999999 !important;
    min-width: 30px;
  }
  footer {
    min-height: 200px;
  }
}

@media only screen and (max-width: 300px) {
  .freebieSign {
    top: 50%;
  }
  .logo {
    min-width: 55px;
  }
  .menu_link {
    padding: 5px 15px 5px 15px;
  }
  .product_card {
    padding: 3px;
    min-width: 130px;
  }
  .fullWidthPadded {
    padding-right: 20px;
    padding-left: 20px;
  }
  .categoryGrid {
    padding: 0;
  }
  .basket {
    top: 5px;
  }
  .header-objet {
    font-size: 10px;
  }
  .overscrollPadded {
    padding-left: 20px;
  }
  .menu_link {
    padding: 5px 15px 5px 15px;
  }
}

.thirtypercent {
  color: var(--menuGrey) !important;
}
.thirtypercentOpacity {
  opacity: 0.6 !important;
}
.inactive {
  opacity: 40%;
}

.videoContainer {
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid pink;
}

.videoContainer div:nth-child(1),
.videoContainer div:nth-child(2) {
  /* position: absolute !important;
  top: 0 !important;
  bottom: 0;
  left: 0 !important;
  right: 0;
  width: 100px;
  min-width: 100% !important;
  height: 100px;
  min-height: 100% !important;
  padding: 10%; */
}
.iframe {
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0;
  left: 0 !important;
  right: 0;
  width: 100px;
  min-width: 100% !important;
  height: 100px;
  min-height: 100% !important;
}
.underlined,
.externalLink {
  text-decoration: underline !important;
}

.cover {
  object-fit: cover;
  width: 100%;
}
